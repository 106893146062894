import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { BehaviorSubject, Observable } from "rxjs";
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  currentMessage = new BehaviorSubject<any>(null);
  private messaging: any;
  constructor(private apiService: ApiService, private afMessaging: AngularFireMessaging,) {
     
    this.requestPermission()
    this.listenForMessages()
  }
  deviceToken: any

  requestPermission() {
    this.afMessaging.requestToken.subscribe({
        next: (token) => {
        if (token) {
          this.deviceToken = token;
          console.log('Device token acquired:', token);
        }
      },
      error: (error) => this.handleError('Unable to get permission to notify.', error),
      complete: () => {}
    }
    );
  }

  // requestPermission() {
  //   // Request permission to send push notifications

  //   return this.afMessaging.requestToken.subscribe(
  //     (token) => {
  //       this.deviceToken =   token;
  //     },
  //     (error) => {
  //       console.error('Unable to get permission to notify.', error);
  //     }
  //   );

  // }

  // requestPermission() {
  //   getToken(this.messaging, { vapidKey: environment.firebaseConfig.apiKey })  // Replace with your VAPID key
  //     .then((token) => {
  //       if (token) {
  //         this.deviceToken = token;
  //         console.log('Device token acquired:', token);
  //       }
  //     })
  //     .catch((error) => this.handleError('Unable to get permission to notify.', error));
  // }

  adminLogin(data: any): Observable<any> {
    const path = `/adminLogin`;
    const obj = {
      devicePlatform: "web",
      deviceUniqueId: "webadmin",
      deviceToken: this.deviceToken,
      deviceModel: "web",
      os: "ubuntu",
    };
    
    Object.assign(data, obj);

    return this.apiService.postRequest(path, data);
  }

  private handleError(errorMessage: string, error: any) {
    console.error(errorMessage, error);
  }

  // listenForMessages() {
  //   onMessage(this.messaging, (message) => {
  //     this.currentMessage.next(message);
  //     console.log('New message received:', message);
  //   });
  // }

  // private handleError(errorMessage: string, error: any) {
  //   console.error(errorMessage, error);
  // }

  // listenForMessages() {
  //   this.afMessaging.messages
  //     .subscribe((message) => {
  //       this.currentMessage.next(message);
  //     });
  // }

  listenForMessages() {
    this.afMessaging.messages.subscribe((message) => {
      this.currentMessage.next(message);
      console.log('New message received:', message);
    });
  }


  forgetPassword(data: any) {
    const path = `/forgotPassword`;
    return this.apiService.postRequest(path, data);
  }
  checkResetToken(data: any) {
    const path = `/checkResetToken`;
    return this.apiService.postRequest(path, data);
  }

  resetPassword(data: any) {
    const path = `/resetPassword`;
    return this.apiService.postRequest(path, data);
  }
  changePassword(data: any) {
    const path = `/changePassword`;
    return this.apiService.postRequest(path, data, true);
  }

  login(data: any): Observable<any> {
    const path = `/login`;
    const obj = {
      devicePlatform: "web",
      deviceUniqueId: "webadmin",
      deviceToken:
        "APA91bHjsWaDEq8ASKK5DRzB9GBsH7Z5sasdsadsadsadsadsar965674857YP_CCMm4m87Zw7RujdTV6m525S6U9ssEt_84dTqu0GuAFDbXXUmAhCK63JjnMiA0fPtC1cdsadasdsaderer",
      deviceModel: "web",
      os: "ubuntu",
    };
    Object.assign(data, obj);

    return this.apiService.postRequest(path, data);
  }
}
