import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriverRequestComponent } from './driver-request.component';
import { DriverRoutes } from './driver-request.routing';
import { DriverDetailComponent } from './driver-detail/driver-detail.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
@NgModule({
  imports: [
    CommonModule,
    DriverRoutes,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    BsDatepickerModule
  ],
  declarations: [DriverRequestComponent, DriverDetailComponent]
})
export class DriverRequestModule { }
