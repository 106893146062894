import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { AuthService } from "src/app/services/auth/auth.service";
import { passwordValidators } from "./password.validators";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  errMsg: string;
  isApiWorked: boolean = false;
  submitted: boolean = false;
  token: string;
  isAdmin: string;
  isAdminUser: boolean = true;
  isTokenValid: boolean = false;
  passwordHidden: boolean = false;
  newPasswordHidden: boolean = false;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private _authService: AuthService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    let bodyclass = document.getElementsByTagName("body");
    bodyclass[0].classList.add("bg-gradient-primary");
    this.token = this.route.snapshot.paramMap.get("token");
    this.isAdmin = this.route.snapshot.paramMap.get("isAdmin");
    this.isTokenValidOrNot();
  }
  isTokenValidOrNot() {
    const obj = {
      passwordResetToken: this.token,
    };
    this._authService.checkResetToken(obj).subscribe({
      next: (res: any) => {
        if (
          res !== undefined &&
          res !== null &&
          res !== "" &&
          res.response.isResetToken
        ) {
          this.isTokenValid = true;
          this.formLoad();
        } else {
          this.isTokenValid = false;
          this.errMsg = "Reset password token is expired";
        }
      },
      error: (err) => {
        throw err;
      },
      complete: () => {}
    });
  }
  resetPassword() {
    this.submitted = true;
    this.errMsg = undefined;
    if (this.resetPasswordForm.invalid) return true;
    this.isApiWorked = true;
    this.resetPasswordForm.value.passwordResetToken = this.token;
    this._authService.resetPassword(this.resetPasswordForm.value).subscribe({
      next: (res: any) => {
        this.isApiWorked = false;
        if (
          res !== undefined &&
          res !== null &&
          res !== "" &&
          res.errorMsg === ""
        ) {
            if(this.isAdmin === "true" && this.isAdminUser){
              this.toastr.success('Your password has been reset successfully!!');
              this.router.navigateByUrl("/login");
            }else {
              this.isAdminUser = false;
            }
        } else {
          this.errMsg = res.errorMsg;
        }
      },
      error: (err) => {
        this.isApiWorked = false;
        throw err;
      },
      complete: () => {}
    });
  }
  formLoad() {
    this.resetPasswordForm = this.fb.group(
      {
        newPassword: ["", [Validators.required, Validators.minLength(6)]],
        passwordResetToken: [""],
        confirmPassword: ["", Validators.required],
      },
      {
        validator: passwordValidators.PasswordShouldMatch(
          "newPassword",
          "confirmPassword"
        ),
      }
    );
  }
  showPassword() {
    this.passwordHidden = !this.passwordHidden;
  }
  showNewPassword() {
    this.newPasswordHidden = !this.newPasswordHidden;
  }
  get resetPasswordF() {
    return this.resetPasswordForm.controls;
  }
}
