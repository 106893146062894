import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { Router } from '@angular/router';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ottlo-ui';
  message: any;

  constructor(private authService: AuthService, private router: Router) { }
  ngOnInit() {
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (!event.data.action) {
        return
      }
    
      switch (event.data.action) {
        case 'redirect-from-notificationclick':
          window.location.href = event.data.url
          break
        // no default
      }
    })


    this.authService.currentMessage.subscribe((message) => {
      this.message = message;
      if (message && Notification.permission === 'granted') {
        this.showNotification(message);
      }
    });


  }

  showNotification(message: any) {
    console.log(message);
    
    const notificationTitle = message.notification.title;
    const notificationOptions = {
      body: message.notification.body,
      icon: './assets/fav-icon/favicon-16x16.png',
      data: { click_action: message.notification.click_action || 'https://google.com' },
      click_action: message.notification.click_action
    };
    const notification = new Notification(notificationTitle, notificationOptions);

    this.handleNotificationEvents(notification);
  }

  handleNotificationEvents(notification: Notification) {
    notification.addEventListener("click", () => {
      notification.close();      
      this.router.navigateByUrl('/company/orders/order-detail?id='+ this.message.data.payload);      
    });    
  }
}
