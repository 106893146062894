<div class="container-fluid px-3">

  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Restaurant Requests</h1>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-12 col-xl-12 col-sm-12">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 fw-bold text-primary">Filters for Restaurant</h6>
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <form [formGroup]="resturantRequestForm">
            <div class="row">
              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Name / Phone no</label>
                  <input type="search" class="form-control " placeholder="Name / Phone no" formControlName="name">
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Request Status</label>
                  <select class="form-select" formControlName="status">
                    <option value="">- Select Request Status -</option>
                    <option value="0">Pending </option>
                    <option value="2">Accepted </option>
                    <option value="3">Rejected </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Requested on</label>
                  <!-- <input type="text" #datePicker2  name="daterangeInput" class="form-control" daterangepicker [options]="options" placeholder='Select date range'
                    (selected)="selectedDate($event)" (cancelDaterangepicker)="calendarCanceled()" (applyDaterangepicker)="applySelectedDate($event.picker)" /> -->

                    <input id="inputSelectDate" type="text" class="form-control px-3" [(ngModel)]="dateRangeFilter"
                    placeholder="Select a Date" [bsConfig]="datePickerConfig" bsDaterangepicker [ngModelOptions]="{standalone: true}"
                    (bsValueChange)="selectedDate($event)" aria-label="Username" aria-describedby="inputSelectDate">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 offset-md-8">
                <button class="btn btn-default float-end "
                  (click)="resetFilter()">Reset</button>
                <button class="btn btn-primary text-white float-end me-2" (click)="applyFilter()" [disabled]="isBtnDisabled()">
                  Apply Now</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Content Row -->

  <div class="row">

    <!-- Area Chart -->
    <div class="col-xl-12 col-lg-12">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 align-items-center">
          <div class="row align-items-center">
            <div class="col-lg-9 col-md-6 col-sm-12 text-start">
              <h6 class="m-0 fw-bold text-primary">All Requests</h6>
            </div>
            <!-- <div class="col-lg-3 col-md-6 col-sm-12 text-end">
              <div class="dataTables_filter d-flex align-items-center">
                <label class="me-2 mb-0">Search:</label><input type="search"
                  class="form-control form-control-sm" placeholder="e.g. name" aria-controls="dataTable">
              </div>
            </div> -->
          </div>
        </div>
        <!-- Card Body -->

        <div class="card-body" *ngIf="
        (resturantsData !== undefined &&
          resturantsData !== null &&
          resturantsData.length > 0) ||
        isLoading
      ">
          <!-- START TABLE DATA -->
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>E-mail</th>
                  <th>Contact No.</th>
                  <th>Registered Since</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
                <tr class="t-loading" *ngIf="isLoading">
                  <td colspan="6" class="text-center">
                    <div class="spinner-grow" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
                <tr *ngFor="let item of resturantsData;let i=index;">
                  <td>{{ (pageNumber - 1) * pageSize + i + 1 }}</td>
                  <td>{{item?.name}}</td>
                  <td>
                    {{
                    item === undefined || item.user === undefined
                    ? "N/A"
                    : item.user.email
                    }}
                  </td>
                  <td>
                    {{
                    item === undefined || item.user === undefined
                    ? "N/A"
                    : item.user.countryCode +' '+ item.user.phone
                    }}
                  </td>
                  <td>{{ item.createdAt | date: "medium" }}</td>
                  <td>
                    <label *ngIf="item.status===0" class="badge badge-pill text-bg-warning">Pending</label>
                    <label *ngIf="item.status===2" class="badge badge-pill text-bg-success text-white">Accepted</label>
                    <label *ngIf="item.status===3" class="badge badge-pill text-bg-danger text-white">Rejected</label>
                  </td>
                  <td>
                    <div class="btn-group" *ngIf="item.status===0">
                      <button type="button" class="btn btn-primary text-white btn-sm dropdown-toggle" data-bs-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        Actions
                      </button>
                      <div class="dropdown-menu" x-placement="bottom-start"
                        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 31px, 0px);">
                        <a class="dropdown-item text-primary" href="javascript:void(0);"
                          routerLink="/restaurant-request/restaurant-detail" routerLinkActive="active"
                          [queryParams]="{id: item.user.id,pageNo:selectedPage}"><i class="fas fa-eye me-1"></i>
                          View</a>
                        <a class="dropdown-item text-primary" href="javascript:void(0);"
                          (click)="returantRequestIsAcceptOrReject(2,item.id)"><i class="fas fa-check me-1"></i>
                          Accept</a>
                        <a (click)="returantRequestIsAcceptOrReject(3,item.id)" href="javascript:void(0);"
                          class="dropdown-item text-danger"><i class="fas fa-times me-1"></i>
                          Reject</a>
                      </div>
                    </div>
                    <a *ngIf="item.status!==0" href="javascript:void(0);"
                      routerLink="/restaurant-request/restaurant-detail" routerLinkActive="active"
                      [queryParams]="{id: item.user.id,pageNo:selectedPage}" class="btn btn-primary text-white btn-sm"><i
                        class="fas fa-eye"></i> </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row" *ngIf="resturantsData.length > 0">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info pt11px" id="dataTable_info" role="status" aria-live="polite">
                Showing {{ (selectedPage - 1) * pageSize + 1 }} to
                {{ (selectedPage - 1) * pageSize + resturantsData.length }} of
                {{ totalLength }} entries
              </div>
            </div>
            <div class="col-sm-12 col-md-7">
              <div class="dataTables_paginate float-end paging_simple_numbers" id="dataTable_paginate">
                <ngb-pagination [maxSize]="5" [rotate]="true" [(page)]="finalPage" [pageSize]="pageSize"
                  [collectionSize]="totalLength" (pageChange)="setPaginationPage($event)">
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="
  (resturantsData === undefined || resturantsData.length === 0) &&
  !isLoading
">
          <div class="no-image-area">
            <p class="title">No Resturants Request Found</p>

          </div>
        </div>

      </div>
    </div>

  </div>

</div>