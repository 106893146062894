<div class="container-fluid px-3">
  <!-- Content Row -->

  <div class="row">

    <div class="col-md-6">
      <div class="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 class="h3 mb-0 text-gray-800">All Details</h1>
      </div>
    </div>
    <div class="col-md-6">
      <div class="text-end mb-4">
        <a href="javascript:void(0)" (click)="back()" class="btn btn-sm btn-primary text-white btn-icon-split">
          <span class="icon text-white-50">
            <i class="fas fa-angle-left"></i>
          </span>
          <span class="text">Back</span>
        </a>
      </div>
    </div>
  </div>

  <!-- User Information -->
  <div class="row">
    <div class="col-md-12">
      <div class="card shadow mb-4">
        <div class="card-header py-3">
          <div class="row align-items-center">
            <div class="col-sm-6 col-md-6 col-lg-6 text-start">
              <h6 class="m-0 fw-bold text-primary">User Information</h6>
            </div>
            <!-- <div class="col-sm-6 col-md-6 col-lg-6 text-end">
              <a href="javascript:void(0)" class="btn btn-sm btn-primary text-white btn-icon-split" data-bs-toggle="modal"
                data-bs-target=".userinformation">
                <span class="icon text-white-50">
                  <i class="fas fa-edit"></i>
                </span>
                <span class="text">Edit</span>
              </a>
            </div> -->
          </div>
        </div>
        <div class="card-body" *ngIf="
        ( userDetails!==undefined && userDetails?.userInfo !== undefined && userDetails?.userInfo !== null) ||
        isLoading
      ">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="mb-3">
                <p class="mb-0"><small>User Image:</small> </p>
                <img
                  src="{{userDetails?.userInfo?.photo!==undefined && userDetails?.userInfo?.photo?.location!==undefined?userDetails?.userInfo?.photo?.location:_commonService.userImageSrc}}"
                  alt="..." class="img-thumbnail img-res">
              </div>
            </div>
            <div class="col-sm-10 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>First Name:</small> </p>
                    <span class="text-gray-900">{{userDetails?.userInfo?.firstName}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Last Name:</small> </p>
                    <span class="text-gray-900">{{userDetails?.userInfo?.lastName}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>E-mail:</small> </p>
                    <span class="text-gray-900">{{userDetails?.userInfo?.email}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Register Type:</small> </p>
                    <span class="text-gray-900">Customer</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Registered Since:</small> </p>
                    <span class="text-gray-900">{{ userDetails?.userInfo?.createdAt | date: "medium" }}</span>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="
          (userDetails!==undefined && userDetails?.userInfo === undefined || userDetails?.userInfo === null) &&
          !isLoading
        ">
          <div class="no-image-area">
            <p class="title">No User Information Found</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- END User Information -->


  <!-- Restaurant Information -->
  <div class="row" *ngIf="userDetails?.userInfo?.isVendor">
    <div class="col-md-12">
      <!-- Information -->
      <div class="card shadow brb-rl">
        <div class="card-header py-3">
          <div class="row align-items-center">
            <div class="col-sm-6 col-md-6 col-lg-6 text-start">
              <h6 class="m-0 fw-bold text-primary">Restaurant Information</h6>
            </div>
            <!-- <div class="col-sm-6 col-md-6 col-lg-6 text-end">
              <a href="javascript:void(0)" class="btn btn-sm btn-primary text-white btn-icon-split" data-bs-toggle="modal"
                data-bs-target=".restaurantinformation">
                <span class="icon text-white-50">
                  <i class="fas fa-edit"></i>
                </span>
                <span class="text">Edit</span>
              </a>
            </div> -->
          </div>
        </div>
        <div class="card-body" *ngIf="
        ( userDetails!==undefined && userDetails.restaurantInfo !== undefined && userDetails.restaurantInfo !== null) ||
        isLoading
      ">
          <div class="row">
            <div class="col-sm-10 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Restaurant Name:</small> </p>
                    <span class="text-gray-900">{{userDetails?.restaurantInfo?.name}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>E-mail:</small> </p>
                    <span class="text-gray-900">
                      {{ userDetails === undefined || userDetails.email === undefined
                      ? "N/A" : userDetails.email }}
                    </span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Contact No.:</small> </p>
                    <span class="text-gray-900">
                      {{ userDetails?.restaurantInfo === undefined || userDetails?.restaurantInfo?.phone === undefined
                      ? "N/A" : userDetails?.restaurantInfo?.countryCode + ' '+ userDetails?.restaurantInfo?.phone
                      }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Registered Type:</small> </p>
                    <span class="text-gray-900">Restaurant</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Menu Card Link:</small> </p>
                    <span class="text-gray-900">{{userDetails?.restaurantInfo?.menuLink}}
                      <a href="{{userDetails?.restaurantInfo?.menuLink}}"> <i
                          class="fas fa-external-link-alt ms-1"></i></a>
                    </span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Register Date:</small> </p>
                    <span class="text-gray-900">{{ userDetails?.restaurantInfo?.createdAt | date: "medium" }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="mb-3">
                    <p class="mb-0"><small>Address:</small> </p>
                    <span class="text-gray-900">{{userDetails?.restaurantInfo?.address?.address}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="mb-3">
                    <p class="mb-0"><small>Restaurant Image:</small> </p>
                    <img
                      src="{{userDetails?.restaurantInfo?.photo!==undefined && userDetails?.restaurantInfo?.photo?.location!==undefined?userDetails?.restaurantInfo?.photo?.location:_commonService.restaurantImageSrc}}"
                      alt="..." class="img-thumbnail img-res">
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="
          (userDetails!==undefined && userDetails.restaurantInfo === undefined || userDetails.restaurantInfo === null) &&
          !isLoading
        ">
          <div class="no-image-area">
            <p class="title">No Resturant Information Found</p>
          </div>
        </div>
      </div>
      <!-- END Information -->

      <!-- Bank Information -->
      <div class="card shadow mb-4 brt-rl">
        <div class="card-header py-3">
          <div class="row align-items-center">
            <div class="col-sm-6 col-md-6 col-lg-6 text-start">
              <h6 class="m-0 fw-bold text-primary">Bank Information</h6>
            </div>
            <!-- <div class="col-sm-6 col-md-6 col-lg-6 text-end">
              <a href="javascript:void(0)" class="btn btn-sm btn-primary text-white btn-icon-split" data-bs-toggle="modal"
                data-bs-target=".restaurantinformation">
                <span class="icon text-white-50">
                  <i class="fas fa-edit"></i>
                </span>
                <span class="text">Edit</span>
              </a>
            </div> -->
          </div>
        </div>
        <div class="card-body" *ngIf="
        ( userDetails!==undefined && userDetails.restaurantBankInfo !== undefined && userDetails.restaurantBankInfo !== null) ||
        isLoading
      ">
          <div class="row">
            <div class="col-sm-10 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  <div class="mb-3">
                    <p class="mb-0"><small>Bank Name:</small> </p>
                    <span class="text-gray-900">{{userDetails?.restaurantBankInfo?.bankName}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  <div class="mb-3">
                    <p class="mb-0"><small>Account No.:</small> </p>
                    <span class="text-gray-900">{{userDetails?.restaurantBankInfo?.accountNumber}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  <div class="mb-3">
                    <p class="mb-0"><small>Branch Name:</small> </p>
                    <span class="text-gray-900">{{userDetails?.restaurantBankInfo?.branchName}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  <div class="mb-3">
                    <p class="mb-0"><small>IFSC Code:</small> </p>
                    <span class="text-gray-900">{{userDetails?.restaurantBankInfo?.ifscCode}}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="
          (userDetails!==undefined && userDetails.restaurantBankInfo === undefined || userDetails.restaurantBankInfo === null) &&
          !isLoading
        ">
          <div class="no-image-area">
            <p class="title">No Bank Information Found</p>
          </div>
        </div>
      </div>
      <!-- END Bank Information -->
    </div>
  </div>
  <!-- END Restaurant Information -->

  <!-- Driver Information -->
  <div class="row" *ngIf="userDetails?.userInfo?.isDriver">
    <!-- Documents -->
    <div class="col-md-12 col-lg-12 col-xl-12">
      <div class="card shadow brb-rl">
        <div class="card-header py-3">
          <div class="row align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-12 text-start">
              <h6 class="m-0 fw-bold text-primary">Driver Information</h6>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="
        ( userDetails!==undefined && userDetails.driverInfo !== undefined && userDetails.driverInfo !== null) ||
        isLoading
      ">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="mb-3">
                    <p class="mb-0"><small>Driver Image:</small> </p>
                    <img
                      src="{{userDetails?.driverInfo?.photo!==undefined && userDetails?.driverInfo?.photo?.location!==undefined?userDetails?.driverInfo?.photo?.location:_commonService.driverImageSrc}}"
                      alt="..." class="img-thumbnail img-res">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>First Name:</small> </p>
                    <span class="text-gray-900">{{userDetails?.userInfo?.firstName}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Last Name:</small> </p>
                    <span class="text-gray-900">{{userDetails?.userInfo?.lastName}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small> Contact No.:</small> </p>
                    <span class="text-gray-900">{{userDetails?.driverInfo?.countryCode}}
                      {{userDetails?.driverInfo?.phone}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Register Type:</small> </p>
                    <span class="text-gray-900">Driver</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Address:</small> </p>
                    <span class="text-gray-900">{{userDetails?.driverInfo?.address?.address}}</span>
                  </div>
                </div>


                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Vehicle Name:</small> </p>
                    <span class="text-gray-900">{{userDetails?.driverInfo?.vehicle?.name }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Vehicle Make:</small> </p>
                    <span class="text-gray-900">{{userDetails?.driverInfo?.vehicleMake }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Vehicle Model:</small> </p>
                    <span class="text-gray-900">{{userDetails?.driverInfo?.vehicleModel }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Vehicle Color:</small> </p>
                    <span class="text-gray-900">{{userDetails?.driverInfo?.vehicleColor }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Food Delivery:</small> </p>
                    <span class="text-gray-900">{{ userDetails?.driverInfo?.isFoodDelivery === 1 ? "Yes" : "No"
                      }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Other Delivery:</small> </p>
                    <span class="text-gray-900">{{ userDetails?.driverInfo?.isMarketplaceDelivery === 1 ? "Yes" : "No"
                      }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Bulk Item Delivery:</small> </p>
                    <span class="text-gray-900">
                      {{ userDetails?.driverInfo?.isBulkItemDelivery === 1 ? "Yes" : "No" }}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Food Charge:</small> </p>
                    <span class="text-gray-900">{{userDetails?.driverInfo?.foodCharge | number: '.2' | currency}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Other Charge:</small> </p>
                    <span class="text-gray-900">{{userDetails?.driverInfo?.marketplaceCharge | number: '.2' |
                      currency}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4"
                  *ngIf="userDetails?.driverInfo?.isBulkItemDelivery === 1">
                  <div class="mb-3">
                    <p class="mb-0"><small>Bulk Item Weight:</small> </p>
                    <span class="text-gray-900" *ngIf="userDetails?.driverInfo?.bulkItemWeight === 1">50
                      pounds or less</span>
                    <span class="text-gray-900" *ngIf="userDetails?.driverInfo?.bulkItemWeight === 2">91-99
                      pounds</span>
                    <span class="text-gray-900" *ngIf="userDetails?.driverInfo?.bulkItemWeight === 3">100+
                      pounds</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4" *ngIf="userDetails?.driverInfo?.areaServe">
                  <div class="mb-3">
                    <p class="mb-0"><small>Area Serve:</small> </p>
                    <span class="text-gray-900">{{userDetails?.driverInfo?.areaServe.name}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Licence:</small> </p>
                    <span class="text-gray-900">{{userDetails?.driverInfo?.licence}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <div class="mb-3">
                    <p class="mb-0"><small>Registered Since:</small> </p>
                    <span class="text-gray-900">{{ userDetails?.driverInfo?.createdAt | date: "medium" }}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="
          (userDetails!==undefined && userDetails.driverInfo === undefined || userDetails.driverInfo === null) &&
          !isLoading
        ">
          <div class="no-image-area">
            <p class="title">No Driver Information Found</p>
          </div>
        </div>
      </div>
      <div class="card shadow mb-4 brt-rl">
        <div class="card-header py-3">
          <div class="row align-items-center">
            <div class="col-sm-6 col-md-6 col-lg-6 text-start">
              <h6 class="m-0 fw-bold text-primary">Bank Information</h6>
            </div>
            <!-- <div class="col-sm-6 col-md-6 col-lg-6 text-end">
              <a href="javascript:void(0)" class="btn btn-sm btn-primary text-white btn-icon-split" data-bs-toggle="modal"
                data-bs-target=".restaurantinformation">
                <span class="icon text-white-50">
                  <i class="fas fa-edit"></i>
                </span>
                <span class="text">Edit</span>
              </a>
            </div> -->
          </div>
        </div>
        <div class="card-body" *ngIf="
        ( userDetails!==undefined && userDetails.driverBankInfo !== undefined && userDetails.driverBankInfo !== null) ||
        isLoading
      ">
          <div class="row">
            <div class="col-sm-10 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  <div class="mb-3">
                    <p class="mb-0"><small>Bank Name:</small> </p>
                    <span class="text-gray-900">{{userDetails?.driverBankInfo?.bankName}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  <div class="mb-3">
                    <p class="mb-0"><small>Account No.:</small> </p>
                    <span class="text-gray-900">{{userDetails?.driverBankInfo?.accountNumber}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  <div class="mb-3">
                    <p class="mb-0"><small>Branch Name:</small> </p>
                    <span class="text-gray-900">{{userDetails?.driverBankInfo?.branchName}}</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                  <div class="mb-3">
                    <p class="mb-0"><small>IFSC Code:</small> </p>
                    <span class="text-gray-900">{{userDetails?.driverBankInfo?.ifscCode}}</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="
          (userDetails!==undefined && userDetails.driverBankInfo === undefined || userDetails.driverBankInfo === null) &&
          !isLoading
        ">
          <div class="no-image-area">
            <p class="title">No Bank Information Found</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-md-12 col-lg-12 col-xl-12 col-sm-12">
      <div class="card shadow mb-4">
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 fw-bold text-primary">Order Information</h6>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Restaurant Name</th>
                  <th>Contact No.</th>
                  <th>Order Date</th>
                  <th>Driver Info</th>
                  <th>Order Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
                <tr>
                  <td>1</td>
                  <td>
                    <div class="user-d">
                      <p class="mb-1">
                        Togather Caffe
                      </p>
                    </div>
                    <div class="address mt-0">
                      <small>
                        <p class="mb-0">New J Rd, Nr. ABC Bank Edmonton, Top Corner Canada</p>
                      </small>
                    </div>
                  </td>
                  <td>
                    0 999 888 77 66
                  </td>
                  <td>
                    23<sup>rd</sup> May, 2020, 12:00 PM
                  </td>
                  <td>
                    <div class="user-d">
                      <div class="user-d">
                        <p class="mb-0">
                          Kat Mitchell
                        </p>
                      </div>
                      <div class="address mt-0">
                        <small>
                          0 789 456 12 23
                        </small>
                      </div>
                    </div>
                  </td>
                  <td>
                    <label class="badge badge-pill text-bg-warning">Pending</label>
                  </td>
                  <td>
                    <a href="order-details.html" class="btn btn-primary text-white btn-sm"><i class="fas fa-eye"></i>
                    </a>
                  </td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>
                    <div class="user-d">
                      <p class="mb-1">
                        Matt’s Rustic
                      </p>
                    </div>
                    <div class="v-email mt-0">
                      <small>
                        <p class="mb-0">Old J Rd, Nr. ABC Bank Edmonton, Top Corner Canada</p>
                      </small>
                    </div>
                  </td>
                  <td>
                    0 876 888 55 43
                  </td>
                  <td>
                    23<sup>rd</sup> May, 2020, 12:00 PM
                  </td>
                  <td>
                    <div class="user-d">
                      <div class="user-d">
                        <p class="mb-0">
                          Kat Mitchell
                        </p>
                      </div>
                      <div class="address mt-0">
                        <small>
                          0 789 456 12 23
                        </small>
                      </div>
                    </div>
                  </td>
                  <td>
                    <label class="badge badge-pill text-bg-success text-white">Completed</label>
                  </td>
                  <td>
                    <a href="js:void(0);" class="btn btn-primary text-white btn-sm"><i class="fas fa-eye"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="dataTables_info" id="dataTable_info" role="status" aria-live="polite">Showing 1 to 10
                of 57 entries
              </div>
            </div>
            <div class="col-md-6">
              <div class="dataTables_paginate paging_simple_numbers float-end" id="dataTable_paginate">
                <ul class="pagination">
                  <li class="paginate_button page-item previous disabled" id="dataTable_previous"><a href="javascript:;"
                      aria-controls="dataTable" data-dt-idx="0" tabindex="0" class="page-link">Previous</a>
                  </li>
                  <li class="paginate_button page-item active"><a href="javascript:;" aria-controls="dataTable"
                      data-dt-idx="1" tabindex="0" class="page-link">1</a>
                  </li>
                  <li class="paginate_button page-item "><a href="javascript:;" aria-controls="dataTable" data-dt-idx="2"
                      tabindex="0" class="page-link">2</a></li>
                  <li class="paginate_button page-item "><a href="javascript:;" aria-controls="dataTable" data-dt-idx="3"
                      tabindex="0" class="page-link">3</a></li>
                  <li class="paginate_button page-item "><a href="javascript:;" aria-controls="dataTable" data-dt-idx="4"
                      tabindex="0" class="page-link">4</a></li>
                  <li class="paginate_button page-item "><a href="javascript:;" aria-controls="dataTable" data-dt-idx="5"
                      tabindex="0" class="page-link">5</a></li>
                  <li class="paginate_button page-item "><a href="javascript:;" aria-controls="dataTable" data-dt-idx="6"
                      tabindex="0" class="page-link">6</a></li>
                  <li class="paginate_button page-item next" id="dataTable_next"><a href="javascript:;"
                      aria-controls="dataTable" data-dt-idx="7" tabindex="0" class="page-link">Next</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->


</div>