import { Component, OnInit } from '@angular/core';
import { ChartData, ChartDataset } from 'chart.js';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  // pie chart
  public pieChartType: string = 'pie';

  startDate: any;
  endDate: any;
  nextBtnDisable: boolean;
  date: any;

  //line charts
  public lineChartData: ChartDataset[] = [{ data: [], label: '' },];
  public lineChartLabels: any[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  public lineChartOptions: any = {
    responsive: true,
  };

  chartLabel: any = ['Customers', 'Companies', 'Drivers'];
  chartsData: any[] = [30, 20, 35];
  chartColor: any[] = ['#4e73df', '#1cc88a', '#f6c23e'];
  chartData: ChartData = {
    labels: this.chartLabel,
    datasets: [
      {
        data: this.chartsData,
        backgroundColor: this.chartColor
      },
    ],
  };

  public pieChartOptions: any  = {
    responsive: true,
    maintainAspectRatio: false,
  };

  public lineChartColors: any[] = [
    {
      borderColor: 'rgba(255,0,0,0.3)',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];

  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  isLoading: boolean;
  dashboardData: any;
  cashbackAmount: any;
  temp: any;
  constructor(
    private _dashboardService: DashboardService,
    private toastrService: ToastrService
  ) { }
  ngOnInit() {
    this.getCurrentStartAndEndDate();
    this.getDashboardDataPointData();
    this.getRestaurantRequestst();
    this.getCashbackAmount();
    this.temp = 12000;
  }
  
  getDashboardDataPointData() {
    this.isLoading = true;
    this._dashboardService
      .dashBoardDataPoint()
      .subscribe({
        next: (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== null &&
            res.response !== ""
          ) {
            this.dashboardData = res.response;
            this.chartsData = [this.dashboardData.userCount, this.dashboardData.companyCount, this.dashboardData.driverCount]
            
            this.chartData.datasets[0].data = this.chartsData;

          } else {
            this.toastrService.error(res.errorMsg)
          }
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          throw err;
        },
        complete: () => {}
      });
  }

  getRestaurantRequestst() {

    this.startDate = this.startDate.getFullYear() + "-" + ((this.startDate.getMonth()) < 8 ? '0' : '') + (this.startDate.getMonth() + 1) + "-" + this.startDate.getDate();
    this.endDate = this.endDate.getFullYear() + "-" + ((this.endDate.getMonth()) < 8 ? '0' : '') + (this.endDate.getMonth() + 1) + "-" + this.endDate.getDate();
    this._dashboardService
      .getRestaurantRequestst(this.startDate, this.endDate)
      .subscribe({
        next: (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== null &&
            res.response !== ""
          ) {
            this.lineChartLabels = [];
            this.lineChartData = [];
            let chartData = [];
            for (let i = 0; i < res.response.length; i++) {
              this.lineChartLabels.push(res.response[i].label);
              chartData.push(res.response[i].total);
            }
            this.lineChartData = [
              { data: chartData, label: 'Series A' },
            ];
          } else {
            this.toastrService.error(res.errorMsg)
          }
        },
        error: (err) => {
          throw err;
        },
        complete: () => {}
      });
  }

  getCurrentStartAndEndDate() {
    let today = new Date();
    this.date = today.getFullYear();
    this.endDate = this.setDateForDatePicker(31, 11, this.date);
    this.startDate = this.setDateForDatePicker(1, 0, this.date);

    if (this.endDate >= new Date()) {
      this.nextBtnDisable = true;
    } else {
      this.nextBtnDisable = false;
    }
  }

  getCashbackAmount() {
    this.isLoading = true;
    this._dashboardService
      .cashbackAmount()
      .subscribe({
        next: (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== null &&
            res.response !== ""
          ) {
            this.cashbackAmount = res.response;
          } else {
            this.toastrService.error(res.errorMsg)
          }
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          throw err;
        },
        complete: () => {}
      });
  }

  getPriviousStartAndEndDate() {
    this.date = this.date - 1;
    this.endDate = this.setDateForDatePicker(31, 11, this.date);
    this.startDate = this.setDateForDatePicker(1, 0, this.date);
    if (this.endDate >= new Date()) {
      this.nextBtnDisable = true;
    } else {
      this.nextBtnDisable = false;
    }
    this.getRestaurantRequestst();
  }

  getNextStartAndEndDate() {
    this.date = this.date + 1;
    this.endDate = this.setDateForDatePicker(31, 11, this.date);
    this.startDate = this.setDateForDatePicker(1, 0, this.date);

    if (this.endDate >= new Date()) {
      this.nextBtnDisable = true;
    } else {
      this.nextBtnDisable = false;
    }
    this.getRestaurantRequestst();
  }

  setDateForDatePicker(dd?: number, mm?: number, yyyy?: number): any {
    let today = new Date();
    dd = dd == undefined ? today.getDate() : dd;
    mm = mm == undefined ? today.getMonth() : mm;
    yyyy = yyyy == undefined ? today.getFullYear() : yyyy;
    return new Date(yyyy, mm, dd);
  }

}
