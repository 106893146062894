import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestaurantReportService } from 'src/app/services/restaurant-report/restaurant-report.service';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-restaurant-reports',
  templateUrl: './restaurant-reports.component.html',
  styleUrls: ['./restaurant-reports.component.scss']
})
export class RestaurantReportsComponent implements OnInit {
  @ViewChild("cancel") cancelBtn;

  pageSize: any;
  selectedPage: any;
  isLoading: boolean = false;
  finalPage = 1;
  totalLength: number = 0;
  pageNumber: number = 1;
  collectionSize: number;
  restaurantReportData: any = [];
  restaurantReportsForm: FormGroup;
  year: any = [];
  orderIDs: any;

  constructor(private route: ActivatedRoute, 
    private common: CommonService, 
    private router: Router, 
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private _restaurantReportService: RestaurantReportService) { }

  ngOnInit() {
    const pageSize = this.route.snapshot.queryParamMap.get("pageNo");
    this.pageNumber = pageSize === null ? 1 : parseInt(pageSize);
    this.selectedPage = this.pageNumber;
    this.pageSize = this.common.PAGINATION.default_page_size;
    this.formLoad();
    this.getYear();
    this.restaurantReportsForm.controls["status"].setValue(0);
    this.getRestaurantData();
  }

  formLoad() {
    this.restaurantReportsForm = this.fb.group({
      year: [""],
      month: [""],
      quarterType: [""],
      status: [""]
    });
  }

  getRestaurantData() {
    this.isLoading = true;
    this._restaurantReportService
      .getAllRestaurant(this.restaurantReportsForm.value.year, this.restaurantReportsForm.value.month, this.restaurantReportsForm.value.quarterType, this.restaurantReportsForm.value.status, this.selectedPage, this.pageSize)
      .subscribe({
        next: (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== null &&
            res.response !== "" &&
            Array.isArray(res.response.list) &&
            res.response.list.length > 0
          ) {
            this.totalLength = res.response.total;
            this.collectionSize = res.response.list.length;

            this.restaurantReportData = res.response.list;
            this.finalPage = this.selectedPage;
          }
          else {
            if(res.errorMsg !== ''){
              this.toastrService.error(res.errorMsg);
            }
            this.restaurantReportData = [];
          }
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;

          throw err;
        },
        complete: () => { }
      });
  }

  onPageSizeChange(event) {
    this.pageSize = event.target.value;
    this.getRestaurantData();
  }

  setPaginationPage(event) {
    this.selectedPage = event;
    this.pageNumber = event;
    this.router.navigate(["/restaurant-reports"], {
      queryParams: {
        pageNo: event,
      },
    });
    this.getRestaurantData();
  }

  isBtnDisabled() {
    let isDisabled = false;
    for (const field in this.restaurantReportsForm.controls) { // 'field' is a string
      if (this.restaurantReportsForm.controls[field].value === "") {
        isDisabled = true;
        break;
      }
    }
    return isDisabled;
  }
  resetFilter() {
    this.restaurantReportsForm.controls['status'].patchValue(0);
    this.restaurantReportsForm.controls['year'].patchValue("");
    this.restaurantReportsForm.controls['month'].patchValue("");
    this.restaurantReportsForm.controls['quarterType'].patchValue("");
    this.router.navigate(["/restaurant-reports"], {
      queryParams: {
        pageNo: 1,
      },
    });
    this.getRestaurantData();
  }
  applyFilter() {
    this.pageNumber = 1;
    this.selectedPage = 1;
    this.router.navigate(["/restaurant-reports"], {
      queryParams: {
        pageNo: 1,
      },
    });
    this.getRestaurantData();
  }

  getYear() {
    var year = new Date().getFullYear();
    this.restaurantReportsForm.controls["year"].setValue(year);
    this.year = [];
    this.year.push(year);
    for (var i = 1; i < 5; i++) {
      this.year.push(year - i);
    }
  }

  getOrderIds(orderids) {
    this.orderIDs = JSON.stringify(orderids);
  }

  payment() {
    this._restaurantReportService.adminPay(this.orderIDs).subscribe({
      next: res => {
        if (res !== undefined &&
          res !== null &&
          res !== "" &&
          res.response !== null &&
          res.response !== "") {
          this.cancelBtn.nativeElement.click();
          this.getRestaurantData();
        }else {
          this.toastrService.error(res.errorMsg)
        }
      },
      error: err => {
        this.cancelBtn.nativeElement.click();
        throw err;
      },
      complete: () => { }
    });
  }

  download() {
    this._restaurantReportService.getData(this.restaurantReportsForm.value.year, this.restaurantReportsForm.value.month, this.restaurantReportsForm.value.quarterType, this.restaurantReportsForm.value.status).subscribe(response => {
      if (response.response.record) {
        window.open(response.response.fileUrl);
      } else {
        return;
      }
    });
  }

}
