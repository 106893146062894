import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "src/app/shared/guards/auth.guard";
import { MainComponent } from "./main.component";

const routes: Routes = [
  {
    path: "",
    component: MainComponent,
    children: [
      { path: "", loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] },
      { path: "home", loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] },
      { path: "users", loadChildren: () => import('./user/user.module').then(m => m.UserModule), canActivate: [AuthGuard] },
      { path: "companies", loadChildren: () => import('./company/company.module').then(m => m.CompanyModule), canActivate: [AuthGuard] },
      { path: "orders", loadChildren: () => import('./admin-order/admin-order.module').then(m => m.AdminOrderModule), canActivate: [AuthGuard] },
      { path: "driver-request", loadChildren: () => import('./requests/driver-request/driver-request.module').then(m => m.DriverRequestModule), canActivate: [AuthGuard] },
      { path: "restaurant-request", loadChildren: () => import('./requests/restaurant-request/restaurant-request.module').then(m => m.RestaurantRequestModule), canActivate: [AuthGuard] },
      { path: "restaurant-reports", loadChildren: () => import('./reports/restaurant-reports/restaurant-reports.module').then(m => m.RestaurantReportsModule), canActivate: [AuthGuard] },
      { path: "driver-reports", loadChildren: () => import('./reports/driver-reports/driver-reports.module').then(m => m.DriverReportsModule), canActivate: [AuthGuard] },
      { path: "company-reports", loadChildren: () => import('./reports/company-reports/company-reports.module').then(m => m.CompanyReportsModule), canActivate: [AuthGuard] },
      { path: "**", redirectTo: "/home",  },

    ],
  },
];

export const MainRoutes = RouterModule.forChild(routes);
