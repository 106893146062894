import { AbstractControl } from "@angular/forms";

export class passwordValidators {
  static PasswordShouldMatch(firstControlName, secondControlName) {
    return (AC: AbstractControl) => {
      let firstControlValue = AC.get(firstControlName).value; // to get value in input tag
      let secondControlValue = AC.get(secondControlName).value; // to get value in input tag
      if (firstControlValue != secondControlValue) {
        return { passwordshouldMatch: true };
      } else {
        return false;
      }
    };
  }
}
