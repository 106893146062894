<div class="container-fluid px-3">

  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Restaurant Reports</h1>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-12 col-xl-12 col-sm-12">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h6 class="m-0 fw-bold text-primary">Filters for Reports</h6>
        </div>
        <!-- Card Body -->
        <div class="card-body">
          <form [formGroup]="restaurantReportsForm">
            <div class="row">
              <div class="col-lg-3 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Year</label>
                  <select class="form-select" formControlName="year">
                    <option *ngFor="let year of year">{{year}}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Month</label>
                  <select class="form-select" formControlName="month">
                    <option value="1">JAN </option>
                    <option value="2">FEB </option>
                    <option value="3">MAR </option>
                    <option value="4">APR </option>
                    <option value="5">MAY </option>
                    <option value="6">JUNE </option>
                    <option value="7">JULY </option>
                    <option value="8">AUG </option>
                    <option value="9">SEP </option>
                    <option value="10">OCT </option>
                    <option value="11">NOV </option>
                    <option value="12">DEC </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Quarter Type</label>
                  <select class="form-select" formControlName="quarterType">
                    <option value="1">First Quarter</option>
                    <option value="2">Second Quarter</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Status</label>
                  <select class="form-select" formControlName="status">
                    <option value="0">Both</option>
                    <option value="1">Not Paid</option>
                    <option value="2">Paid</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 offset-md-8">
                <button class="btn btn-default float-end " 
                  (click)="resetFilter()">Reset</button>
                <button class="btn btn-primary text-white float-end me-2" [disabled]="isBtnDisabled()"
                  (click)="getRestaurantData()"> Apply Now</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Content Row -->

  <div class="row">

    <!-- Area Chart -->
    <div class="col-xl-12 col-lg-12">
      <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div class="card-header py-3 align-items-center">
          <div class="row align-items-center">
            <div class="col-lg-8 col-md-12 col-sm-12 text-start">
              <h6 class="m-0 fw-bold text-primary">All Restaurants</h6>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 text-end"
              *ngIf="restaurantReportData !== undefined && restaurantReportData !== null && restaurantReportData.length > 0">
              <a href="javascript:void(0)" (click)="download()" class="btn btn-primary text-white btn-xxs float-end "><i
                  class="fas fa-file-export"></i> Export</a>
            </div>
          </div>
        </div>
        <!-- Card Body -->
        <div class="card-body"
          *ngIf="(restaurantReportData !== undefined && restaurantReportData !== null && restaurantReportData.length > 0) || isLoading">
          <!-- START TABLE DATA -->
          <div class="table-responsive">
            <table class="table" id="restaurant">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Contact No.</th>
                  <th>Bank AC No.</th>
                  <th>Branch Name</th>
                  <th>IFSC Code</th>
                  <th>Tax</th>
                  <th>Service Charges</th>
                  <th>Grand Total</th>
                  <th>Payable Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody class="table-group-divider">
                <tr class="t-loading" *ngIf="isLoading">
                  <td colspan="6" class="text-center">
                    <div class="spinner-grow" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
                <!-- Row 1 -->
                <tr *ngFor="let item of restaurantReportData;let i=index;">
                  <td>{{ (pageNumber - 1) * pageSize + i + 1 }}</td>
                  <td>
                    {{item === undefined || item.vendorName === undefined
                    ? "N/A"
                    : item.vendorName}}
                  </td>
                  <td>
                    {{item.vendorContact.countryCode}}{{item.vendorContact.phone}}
                  </td>
                  <td>{{ item === undefined || item.accountNumber === undefined
                    ? "N/A"
                    : item.accountNumber }}</td>
                  <td>{{ item === undefined || item.branchName === undefined
                    ? "N/A"
                    : item.branchName }}</td>
                  <td>{{ item === undefined || item.ifscCode === undefined
                    ? "N/A"
                    : item.ifscCode }}</td>
                  <td>{{ item === undefined || item.tax === undefined
                    ? "N/A"
                    : item.tax | number: '.2'}}</td>
                  <td>{{ item === undefined || item.serviceCharge === undefined
                    ? "N/A"
                    : item.serviceCharge | number: '.2' }}</td>
                  <td>{{ item === undefined || item.grandTotal === undefined
                    ? "N/A"
                    : item.grandTotal | number: '.2' }}</td>
                  <td>{{ item === undefined || item.restaurantEarning === undefined
                    ? "N/A"
                    : item.restaurantEarning | number: '.2' }}</td>
                  <td>
                    <label *ngIf="item.isAdminPaid===1" class="badge badge-pill text-bg-success text-white">Paid</label>
                    <label *ngIf="item.isAdminPaid===0" class="badge badge-pill text-bg-danger text-white">Unpaid</label>
                  </td>
                  <td>
                    <a *ngIf="item.isAdminPaid===0" (click)="getOrderIds(item.orderIds)" href="javascript:void(0);"
                      data-bs-toggle="modal" data-bs-target="#paidConfirmation" class="btn btn-success btn-sm text-white"><i
                        class="fas fa-check"></i> </a>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- END TABLE DATA -->
          <div class="row" *ngIf="restaurantReportData.length > 0">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info pt11px" id="dataTable_info" role="status" aria-live="polite">
                Showing {{ (selectedPage - 1) * pageSize + 1 }} to
                {{ (selectedPage - 1) * pageSize + restaurantReportData.length }} of
                {{ totalLength }} entries
              </div>
            </div>
            <div class="col-sm-12 col-md-7">
              <div class="dataTables_paginate float-end paging_simple_numbers" id="dataTable_paginate">
                <ngb-pagination [maxSize]="5" [rotate]="true" [(page)]="finalPage" [pageSize]="pageSize"
                  [collectionSize]="totalLength" (pageChange)="setPaginationPage($event)">
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body"
          *ngIf="(restaurantReportData === undefined || restaurantReportData.length === 0) && !isLoading">
          <div class="no-image-area">
            <p class="title">No Restaurant Report Found</p>

          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<div class="modal fade" id="paidConfirmation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ready to Pay?</h5>
        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">
          <!-- <span aria-hidden="true">×</span> -->
        </button>
      </div>
      <div class="modal-body">Select "Payment" below if you are ready to Pay Payment.</div>
      <div class="modal-footer">
        <button class="btn btn-secondary text-white" type="button" data-bs-dismiss="modal" #cancel>Cancel</button>
        <button class="btn btn-primary text-white" (click)="payment()">Payment</button>
      </div>
    </div>
  </div>
</div>