import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/services/users/users.service';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  userId: string;
  userDetails: any;
  isLoading: boolean = false;
  selectedPage: any;
  constructor(
    public _commonService: CommonService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private _userService: UsersService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.userId = this.route.snapshot.queryParamMap.get("id");
    const pageSize = this.route.snapshot.queryParamMap.get("pageNo");
    this.selectedPage = pageSize === null ? 1 : parseInt(pageSize);
    this.getUserDetails();
  }
  back() {
    this.selectedPage === 1 ? this.router.navigateByUrl("/users") : this.router.navigate(["/users"], { queryParams: { pageNo: this.selectedPage } });
  }
  getUserDetails() {
    this._userService
      .userDetails(this.userId)
      .subscribe({
        next: (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== null &&
            res.response !== ""
          ) {
            this.userDetails = res.response;
          } else {
            this.toastrService.error(res.errorMsg)
          }
        },
        error: err => {
          this.isLoading = false;
          throw err;
        },
        complete: () => {}
      })
  }
}
