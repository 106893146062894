
<body id="page-top">
  <!-- Page Wrapper -->
  <div id="wrapper">

    <app-sidebar ></app-sidebar>

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">

      <!-- Main Content -->
      <div id="content">

        <app-header ></app-header>
        <div class="container px-3">
          <h2 class="text-danger text-center">Page Not Found.</h2>
        </div>

      </div>

      <!-- Footer -->
      <app-footer></app-footer>
      <!-- End of Footer -->

    </div>
    <!-- End of Content Wrapper -->

  </div>
  <!-- End of Page Wrapper -->


</body>


