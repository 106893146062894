import { Component, NgZone, OnInit } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  isSidebarVisible: boolean = false;
  isDrpVisible: boolean = false;
  constructor(private localStroageService: LocalStorageService, private router: Router, private ngZone: NgZone,) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.ngOnInit();
      }

    });
  }

  ngOnInit() {
    let prop = document.getElementById('accordionSidebar');
    prop.style.display = "none";
    let dprop = document.getElementById('navdrps');
    prop.style.display = "none";

    let bodyclass = document.getElementsByTagName("body");
    bodyclass[0].classList.remove("bg-gradient-primary");
    const isLoggedIn = localStorage.getItem('userInfo');
    if (isLoggedIn !== undefined && isLoggedIn !== null) {

      prop.style.display = "";
      dprop.style.display = "";
      this.isDrpVisible = true;
    }
  }

}
