import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestaurantRequestService } from 'src/app/services/restaurant-request/restaurant-request.service';
import { CommonService } from 'src/app/shared/common.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant-request.component.html',
  styleUrls: ['./restaurant-request.component.scss']
})
export class RestaurantRequestComponent implements OnInit {
  pageSize: any;
  selectedPage: any;
  isLoading: boolean = false;
  keyWord: "";
  finalPage = 1;
  totalLength: number = 0;
  pageNumber: number = 1;
  collectionSize: number;
  resturantsData: any = [];
  resturantRequestForm: FormGroup;
  name: any;

  filterStartDate: any = '';
  filterEndDate: any = '';
  public daterange: any = {};
  datePickerConfig: Partial<BsDatepickerConfig> = {
    // displayMonths: 1
  };

  dateRangeFilter: Date[];

  // @ViewChild(DaterangepickerComponent)
  // public picker: DaterangepickerComponent;

  @ViewChild('datePicker2')
  public datePicker1: any;

  constructor(
    private _resturantService: RestaurantRequestService, 
    private route: ActivatedRoute, 
    private common: CommonService, 
    private router: Router, 
    private fb: FormBuilder,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    const pageSize = this.route.snapshot.queryParamMap.get("pageNo");
    this.pageNumber = pageSize === null ? 1 : parseInt(pageSize);
    this.selectedPage = this.pageNumber;
    this.pageSize = this.common.PAGINATION.default_page_size;
    this.formLoad();
    this.getResturantsData();
  }

  ngAfterViewInit() {
    // // Set default value to null after view initialization
    if (this.datePicker1) {
      this.datePicker1.nativeElement.value = '';
    }
  }

  formLoad() {
    this.resturantRequestForm = this.fb.group({
      status: [""],
      name: [""],
      startDate: [""],
      endDate: [""]
    });
  }

  getResturantsData() {
    this.isLoading = true;
    this._resturantService
      .getAllResturants(this.selectedPage, this.pageSize, this.resturantRequestForm.value.status, this.resturantRequestForm.value.name, this.filterStartDate, this.filterEndDate)
      .subscribe({
        next: (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== null &&
            res.response !== "" &&
            Array.isArray(res.response.list) &&
            res.response.list.length > 0
          ) {
            this.totalLength = res.response.total;
            this.collectionSize = res.response.list.length;

            this.resturantsData = res.response.list;
            this.finalPage = this.selectedPage;
          }
          else {
            if(res.errorMsg){
              this.toastrService.error(res.errorMsg)
            }
            this.resturantsData = [];
          }
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;

          throw err;
        },
        complete: () => {}
      });
  }

  public applySelectedDate(value: any) {
    // this is the date  selected
    
    const startDate = value.startDate._d;
    const filterStartDate = new Date(startDate);
    var dd = String(filterStartDate.getDate()).padStart(2, '0');
    var mm = String(filterStartDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = filterStartDate.getFullYear();
    this.filterStartDate = yyyy + '-' + mm + '-' + dd;

    const endDate = value.endDate._d;
    const filterEndDate = new Date(endDate);
    var dd = String(filterEndDate.getDate()).padStart(2, '0');
    var mm = String(filterEndDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = filterEndDate.getFullYear();
    this.filterEndDate = yyyy + '-' + mm + '-' + dd;

    this.daterange.start = value.startDate;
    this.daterange.end = value.endDate;
    this.daterange.label = value.label;
    this.setDatePickerValue();
  }

  returantRequestIsAcceptOrReject(status, id) {
    const body = {
      vendorId: id,
      status: status
    };
    this.isLoading = true;
    this._resturantService
      .resturantStatus(body)
      .subscribe({
        next: (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== null &&
            res.response !== ""
          ) {
            this.getResturantsData();
          } else {
            this.toastrService.error(res.errorMsg)
          }
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          throw err;
        },
        complete: () => {}
      });

  }
  onPageSizeChange(event) {
    this.pageSize = event.target.value;
    this.getResturantsData();
  }
  setPaginationPage(event) {
    this.selectedPage = event;
    this.pageNumber = event;
    this.router.navigate(["/restaurant-request"], {
      queryParams: {
        pageNo: event,
      },
    });
    this.getResturantsData();
  }

  public options: any = {
    locale: { format: 'DD-MMM-yyyy' },
    alwaysShowCalendars: false,
  };

  public selectedDate(value: any) {
    // this is the date  selected
    this.dateRangeFilter = value
    const startDate = value[0];
    const filterStartDate = new Date(startDate);
    var dd = String(filterStartDate.getDate()).padStart(2, '0');
    var mm = String(filterStartDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = filterStartDate.getFullYear();
    this.filterStartDate = yyyy + '-' + mm + '-' + dd;

    const endDate = value[1];
    const filterEndDate = new Date(endDate);
    var dd = String(filterEndDate.getDate()).padStart(2, '0');
    var mm = String(filterEndDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = filterEndDate.getFullYear();
    this.filterEndDate = yyyy + '-' + mm + '-' + dd;

    this.daterange.start = value.start;
    this.daterange.end = value.end;
    this.daterange.label = value.label;
    this.setDatePickerValue();

  }
  calendarCanceled() {
    this.filterStartDate = '';
    this.filterEndDate = '';
    this.dateRangeFilter = null;
    // this.picker.datePicker.setStartDate(new Date());
    // this.picker.datePicker.setEndDate(new Date());
    this.setDatePickerValue();
  }

  setDatePickerValue() {
    this.resturantRequestForm.controls['startDate'].patchValue(this.filterStartDate);
    this.resturantRequestForm.controls['endDate'].patchValue(this.filterEndDate);
  }
  isBtnDisabled() {
    let isDisabled = true;
    for (const field in this.resturantRequestForm.controls) { // 'field' is a string
      if (this.resturantRequestForm.controls[field].value !== "") {
        isDisabled = false;
        break;
      }
    }
    return isDisabled;
  }
  resetFilter() {
    this.calendarCanceled();
    this.resturantRequestForm.controls['status'].patchValue("");
    this.resturantRequestForm.controls['name'].patchValue("");
    if (this.datePicker1) {
      this.datePicker1.nativeElement.value = '';
    }
    this.router.navigate(["/restaurant-request"], {
      queryParams: {
        pageNo: 1,
      },
    });
    this.getResturantsData();
  }
  applyFilter() {
    this.pageNumber = 1;
    this.selectedPage = 1;
    this.router.navigate(["/restaurant-request"], {
      queryParams: {
        pageNo: 1,
      },
    });
    this.getResturantsData();
  }
}
