<nav class="navbar navbar-expand navbar-light bg-white topbar px-3 mb-4 static-top shadow">

  <!-- Sidebar Toggle (Topbar) -->
  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle me-3">
    <i class="fa fa-bars"></i>
  </button>

  <!-- Topbar Navbar -->
  <ul class="navbar-nav ms-auto" id="navdrps">

    <!-- Nav Item - User Information -->
    <li class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="javascript:;" id="userDropdown" role="button" data-bs-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <span class="me-2 d-none d-lg-inline text-gray-600 small">{{
          username
        }}</span> <i
          class="fas fa-angle-down"></i>
      </a>
      <!-- Dropdown - User Information -->
      <div class="dropdown-menu dropdown-menu-end shadow animated--grow-in" aria-labelledby="userDropdown">
        <a class="dropdown-item" routerLink='/company/profile' routerLinkActive="active"  *ngIf="userRole.slug === roleEnum.USER && user.isCompany === isCompanyEnum.APPROVED" [ngClass]="{
          disabled: !sidebarService.isProfileCompleted
        }" >
          <i class="fas fa-user fa-sm fa-fw me-2 text-gray-400"></i>
          Profile
        </a>
        <a class="dropdown-item" href="javascript:;" data-bs-toggle="modal" data-bs-target="#changepassword">
          <i class="fas fa-lock fa-sm fa-fw me-2 text-gray-400"></i>
          Change Password
        </a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="javascript:;" data-bs-toggle="modal" data-bs-target="#logoutModal">
          <i class="fas fa-sign-out-alt fa-sm fa-fw me-2 text-gray-400"></i>
          Logout
        </a>
      </div>
    </li>

  </ul>

</nav>