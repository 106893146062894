import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RestaurantDetailComponent } from './restaurant-detail/restaurant-detail.component';
import { RestaurantRequestRoutes } from './restaurant-request.routing';
import { RestaurantRequestComponent } from './restaurant-request.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
@NgModule({
  imports: [
    CommonModule,
    RestaurantRequestRoutes,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule
  ],
  declarations: [RestaurantRequestComponent, RestaurantDetailComponent]
})
export class RestaurantRequestModule { }
