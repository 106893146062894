import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { ThousandSuffixesPipe } from 'src/app/services/Thousand-suffixes.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  imports: [
    CommonModule,
    NgChartsModule,
    NgbModule
  ],
  declarations: [ ThousandSuffixesPipe],
  exports: [ThousandSuffixesPipe]
})
export class SharedModule { }
