import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './modules/login/login.module';
import { ForgetPasswordModule } from './modules/forget-password/forget-password.module';
import { MainModule } from './modules/main/main.module';
import { ResetPasswordModule } from './modules/reset-password/reset-password.module';
import { HttpClientModule } from "@angular/common/http";
import { GlobalErrorHandlerService } from './shared/global-error-handler.service';
import { LocalStorageService } from './shared/local-storage.service';
import { ApiService } from './services/api.service';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { provideZxvbnServiceForPSM } from 'angular-password-strength-meter/zxcvbn';
import { BsDatepickerModule, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoginModule,
    ForgetPasswordModule,
    ResetPasswordModule,
    BsDatepickerModule.forRoot(),
    MainModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    // provideFirebaseApp(() => initializeApp(environment.firebaseConfig))
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFireMessagingModule

  ],
  providers: [GlobalErrorHandlerService, provideZxvbnServiceForPSM(),
    ApiService,
    LocalStorageService,
    { provide: BsDaterangepickerConfig, useFactory: getDatepickerConfig },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function getDatepickerConfig(): Partial<BsDaterangepickerConfig> {
  return Object.assign(new BsDaterangepickerConfig(), {
    containerClass: 'app-theme-primary',
    dateInputFormat: 'DD/MM/YYYY',
    isAnimated: true,
    showWeekNumbers: false,
    adaptivePosition: true,
  });
}
