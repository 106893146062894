<div id="wrapper">
  <app-sidebar></app-sidebar>
  <div id="content-wrapper" class="d-flex flex-column">
    <!-- Main Content -->
    <div id="content">
      <app-header></app-header>
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
  <a class="scroll-to-top rounded" href="#page-top">
    <i class="fas fa-angle-up"></i>
  </a>

  <!-- Change Password Modal -->
  <div class="modal fade" id="changepassword" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Change Password</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"
            aria-label="Close">
            <!-- <span aria-hidden="true">&times;</span> -->
          </button>
        </div>
        <div class="modal-body">

          <form [formGroup]="changePasswordForm">
            <div class="form-group">
              <label class="form-label" for="oldPassword">Old Password</label>
              <input type="password" formControlName="oldPassword"
                class="form-control" id="oldPassword" placeholder="Password">
              <div
                *ngIf="submitted && changePasswordF.oldPassword.errors"
                class="text-danger">
                <div *ngIf="changePasswordF.oldPassword.errors.required">
                  Old Password is required
                </div>
                <div *ngIf="changePasswordF.oldPassword.errors.minlength">
                  Old Password must be at least 6 characters
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label" for="Password">New Password</label>
              <input type="password" class="form-control" id="Password"
                placeholder="Password" formControlName="newPassword"
                aria-describedby="passwordHelpBlock">
              <password-strength-meter [password]="newPassword?.value"
                [enableFeedback]="true"></password-strength-meter>
              <label class="col"
                [ngClass]="changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                <i
                  class="{{ changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('minlength') ? 'fas fa-times-circle' : 'fas fa-check-circle' }}"></i>
                Must be at least 8 characters!
              </label>
              <label class="col"
                [ngClass]="changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                <i
                  class="{{ changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('hasNumber') ? 'fas fa-times-circle' : 'fas fa-check-circle' }}"></i>
                Must contain at least 1 number!
              </label>
              <label class="col"
                [ngClass]="changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                <i
                  class="{{ changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('hasCapitalCase') ? 'fas fa-times-circle' : 'fas fa-check-circle' }}"></i>
                Must contain at least 1 in Capital Case!
              </label>
              <label class="col"
                [ngClass]="changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                <i
                  class="{{ changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('hasSmallCase') ? 'fas fa-times-circle' : 'fas fa-check-circle' }}"></i>
                Must contain at least 1 Letter in Small Case!
              </label>
              <label class="col"
                [ngClass]="changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                <i
                  class="{{ changePasswordForm.controls['newPassword'].hasError('required') || changePasswordForm.controls['newPassword'].hasError('hasSpecialCharacters') ? 'fas fa-times-circle' : 'fas fa-check-circle' }}"></i>
                Must contain at least 1 Special Character!
              </label>
              <div
                *ngIf="submitted && changePasswordF.newPassword.errors"
                class="text-danger">
                <div *ngIf="changePasswordF.newPassword.errors.required">
                  New Password is required
                </div>
                <div *ngIf="changePasswordF.newPassword.errors.minlength">
                  New Password must be at least 6 characters
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="form-label" for="confirmPassword">Confirm Pasasword</label>
              <input type="password" class="form-control" id="confirmPassword"
                placeholder="Confirm Password"
                formControlName="confirmPassword">
              <div
                *ngIf="submitted && changePasswordF.confirmPassword.errors"
                class="text-danger">
                <div
                  *ngIf="changePasswordF.confirmPassword.errors.required">
                  Confirm password is required
                </div>
                <div
                  *ngIf="changePasswordF.confirmPassword.errors.minlength">
                  Confirm password must be at least 6 characters
                </div>
              </div>
              <div
                *ngIf="
  changePasswordForm.errors?.passwordshouldMatch &&
  submitted &&
  changePasswordForm.invalid &&
  !changePasswordF.confirmPassword.errors
"
                class="text-danger">
                Password and confirm password does not match.
              </div>
            </div>
          </form>

          <div class="alert alert-danger mt-2" *ngIf="errMsg" role="alert">
            {{errMsg}}
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary text-white" data-bs-dismiss="modal"
            #chCls>Close</button>
          <button *ngIf="isApiWorked" type="button"
            [disabled]="changePasswordForm.invalid" class="btn btn-primary text-white">
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"></span>
            loading... Change Password
          </button>
          <button [hidden]="isApiWorked" type="button"
            (click)="changePassword()" [disabled]="changePasswordForm.invalid"
            class="btn btn-primary text-white">Change Password</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Logout Modal-->
  <div class="modal fade" id="logoutModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="readyToLeave">Ready to Leave?</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal"
            aria-label="Close">
            <!-- <span aria-hidden="true">×</span> -->
          </button>
        </div>
        <div class="modal-body">Select "Logout" below if you are ready to end
          your current session.</div>
        <div class="modal-footer">
          <button class="btn btn-secondary text-white" type="button" data-bs-dismiss="modal"
            #cancel>Cancel</button>
          <a class="btn btn-primary text-white" (click)="logOut()"
            href="javascript:void(0);">Logout</a>
        </div>
      </div>
    </div>
  </div>
</div>
