import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DriverRequestService } from 'src/app/services/driver-request/driver-request.service';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-driver-detail',
  templateUrl: './driver-detail.component.html',
  styleUrls: ['./driver-detail.component.scss']
})
export class DriverDetailComponent implements OnInit {
  driverRequestDetails: any;
  isLoading: boolean = false;
  requestId: string;
  @ViewChild("aclsBtn") acancelBtn;
  @ViewChild("rclsBtn") rcancelBtn;
  statusId: any;
  selectedPage: number;
  driverBankRequestDetails: any;
  constructor(
    public _commonService: CommonService, 
    private route: ActivatedRoute, 
    private _driverService: DriverRequestService, 
    private router: Router,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {

    this.requestId = this.route.snapshot.queryParamMap.get("id");
    const pageSize = this.route.snapshot.queryParamMap.get("pageNo");
    this.selectedPage = pageSize === null ? 1 : parseInt(pageSize);
    this.getDriverDetails();
    this.getDriverBankdetails();
  }
  back() {
    this.selectedPage === 1 ? this.router.navigateByUrl("/driver-request") : this.router.navigate(["/driver-request"], { queryParams: { pageNo: this.selectedPage } });
  }
  getDriverDetails() {
    this._driverService
      .driverDetails(this.requestId)
      .subscribe({
        next: (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== null &&
            res.response !== ""
          ) {
            this.driverRequestDetails = res.response;
            this.statusId = res.response.id;
          } else {
            this.toastrService.error(res.errorMsg)
          }
        }, 
        error: (err) => {
          this.isLoading = false;
          throw err;
        },
        complete: () => {}
      })
  }

  driverRequestIsAcceptOrReject(status) {
    const body = {
      driverId: this.statusId,
      status: status
    };
    this.isLoading = true;
    this._driverService
      .driverStatus(body)
      .subscribe({
        next: (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== null &&
            res.response !== ""
          ) {
            this.getDriverDetails();
            this.acancelBtn.nativeElement.click();
            this.rcancelBtn.nativeElement.click();
          } else {
            this.toastrService.error(res.errorMsg)
          }
          this.isLoading = false;


        },
        error: (err) => {
          this.isLoading = false;
          throw err;
        },
        complete: () => {}
      });

  }
  getDriverBankdetails() {
    this._driverService
      .driverBankDetails(this.requestId)
      .subscribe({
        next: (res: any) => {
          if (
            res !== undefined &&
            res !== null &&
            res !== "" &&
            res.response !== null &&
            res.response !== ""
          ) {
            this.driverBankRequestDetails = res.response;
          } else {
            this.toastrService.error(res.errorMsg)
          }
        }, 
        error: err => {
          this.isLoading = false;
          throw err;
        },
        complete: () => {}
      })

  }

}
