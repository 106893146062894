import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserComponent } from './user.component';
import { UserRoutes } from './user.routing';

import {
  BsDatepickerModule,
} from 'ngx-bootstrap/datepicker';

@NgModule({
  imports: [
    CommonModule,
    UserRoutes,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    BsDatepickerModule
  ],
  declarations: [UserComponent, UserDetailComponent,]
})
export class UserModule { }

