import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HomeModule } from './home/home.module';
import { MainComponent } from './main.component';
import { MainRoutes } from './main.routing';
import { DriverReportsModule } from './reports/driver-reports/driver-reports.module';
import { RestaurantReportsModule } from './reports/restaurant-reports/restaurant-reports.module';
import { DriverRequestModule } from './requests/driver-request/driver-request.module';

import { RestaurantRequestModule } from './requests/restaurant-request/restaurant-request.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UserModule } from './user/user.module';
import { PasswordStrengthMeterComponent } from 'angular-password-strength-meter';
@NgModule({
  imports: [
    CommonModule,
    MainRoutes,
    HomeModule,
    UserModule,
    RestaurantRequestModule,
    DriverRequestModule,
    RestaurantReportsModule,
    DriverReportsModule,
    ReactiveFormsModule,
    PasswordStrengthMeterComponent,
    FormsModule,
    NgbModule,
  ],
  declarations: [MainComponent, HeaderComponent,
    SidebarComponent,
    FooterComponent],
  exports: [HeaderComponent, SidebarComponent, FooterComponent, NgbModule],
})
export class MainModule { }
